/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

/**
 * Table Of Content
 *
 *  1. Color system
 *  2. Options
 *  3. Body
 *  4. Typography
 *  5. Breadcrumbs
 *  6. Cards
 *  7. Dropdowns
 *  8. Buttons
 *  9. Typography
 *  10. Progress bars
 *  11. Tables
 *  12. Forms
 *  14. Component
 */
@import 'all/custom/variables.scss';

// Import Bootstrap source files (Required file)
@import 'all/bootstrap/bootstrap.scss';
@import 'all/custom/custom.scss';

//This is for the icons (Required file)
@import 'all/icons/font-awesome/css/fontawesome-all.css';
@import 'all/icons/simple-line-icons/css/simple-line-icons.css';
@import 'all/icons/weather-icons/css/weather-icons.css';
@import 'all/icons/themify-icons/themify-icons.css';
@import 'all/icons/flag-icon-css/flag-icon.min.css';
@import 'all/icons/material-design-iconic-font/css/materialdesignicons.min.css';
@import 'all/icons/crypto-icons/cryptocoins.css';

.p-button-warning {
    background-color: #bc000e !important;
    border: 1px solid #bc000e !important;
    color: #fff !important;
}

.p-filter-column input {
    width: 100% !important;
}

.p-fieldset-legend {
    font-size: 15px;
}

.p-checkbox-label {
    .p-checkbox {
        margin-right: 5px !important;
    }
}

.entrega-file-upload {
    .custom-file-upload {
        position: relative;

        .delete-icon {
            position: absolute;
            top: 5px;
            right: 5px;
            background: red;
            color: white;
            border: 2px solid black;
            border-radius: 5px;
            cursor: pointer;

            i {
                font-size: 2rem;
            }
        }

        .image-title {
            position: absolute;
            bottom: 0px;
            background-color: rgba(255, 255, 255, 0.8);
            left: 0;
            margin: 0;
            padding: 10px;
            font-size: 13px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        &.addsection {
            border: 2px dashed #1f1f1f;

            .btn-info {
                height: 100%;
                width: 100%;
                background: transparent;
                border: 0px;

                i {
                    color: lightgray;
                    font-size: 5em;
                }

                span {
                    color: #1d1d1d;
                    display: block;
                }
            }
        }

        width: 100%;
        height: 250px;
        border: 2px solid lightgray;
        border-radius: 10px;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            border-radius: 10px;
        }
    }
}

.p-header {
    margin-bottom: 20px;
    border: 2px solid #c8c8c8 !important;

    .p-fieldset-legend {
        background-color: #bc000e !important;
        border: 1px solid #bc000e !important;
        color: #fff !important;
    }
}

.pointer {
    cursor: pointer;
}

.orderlist-demo .product-item {
    display: flex;
    align-items: center;
    padding: .5rem;
    width: 100%;
}

.orderlist-demo .product-item img {
    width: 75px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 1rem;
}

.orderlist-demo .product-item .product-list-detail {
    flex: 1 1 0;
}

.orderlist-demo .product-item .product-list-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.orderlist-demo .product-item .product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
}

.orderlist-demo .product-item .product-category {
    vertical-align: middle;
    line-height: 1;
}

@media screen and (max-width: 576px) {
    .orderlist-demo .product-item {
        flex-wrap: wrap;
    }

    .orderlist-demo .product-item .image-container {
        width: 100%;
        text-align: center;
    }

    .orderlist-demo .product-item img {
        margin: 0 0 1rem 0;
        width: 100px;
    }
}


.p-toast .p-toast-message.p-toast-message-info {
    border: solid #0891cf !important;
    border-width: 0 0 0 6px !important;
}



.p-toast .p-toast-message.p-toast-message-error {
    border: solid #e8192e !important;
    border-width: 0 0 0 6px !important;
}

.p-toast .p-toast-message.p-toast-message-success {
    border: solid #559e58 !important;
    border-width: 0 0 0 6px !important;
}

.p-dialog-content {
    height: 100% !important;
}

.p-autocomplete-input {
    width: -webkit-fill-available !important;
}

.signature-body {
    legend {
        width: auto !important;
        padding: 5px 10px !important;
        margin-left: 10px;
    }

    .sigBorder {
        border: 2px dashed blue;
    }
}

.signpad-clear {
    float: right;
    border: 2px dashed blue;
    border-bottom: 0px;
    background: lavender;
    color: blue;
    cursor: pointer;
}

.p-component,
.ant-picker {
    font-family: $font-family-sans-serif !important;
}

.sidebar-nav ul .sidebar-item.selected>.sidebar-link,
.sidebar-nav ul .sidebar-item:hover>.sidebar-link {
    color: #414141 !important;
    font-weight: 800;
}

.sidebar-nav ul .sidebar-item .sidebar-link.active {
    color: #bc000e !important;
}

.new_logo {
    display: block;
    border-bottom: 2px dashed #ababab;
    padding: 5px;
    text-align: center;
}

.left-sidebar {
    .pi {
        font-size: 1.5rem !important;
        vertical-align: middle;
    }

    .p-panelmenu .p-panelmenu-header-content {
        background: #fff !important;
        border-color: auto !important;
    }
}

.form-control {
    border-radius: 5px !important;
    border-color: #d3d3d3 !important;
}

.inside-logo {
    position: absolute;
    left: 115px;
    top: 10px;
    height: 65px;
}

.h-45x {
    height: 45px !important;
}

.w-full {
    width: 100% !important;
}

.p-tabview {
    width: 100%;

    .p-tabview-nav {
        margin: 0px;
    }

    .p-tabview-panels {
        padding: 0px;
    }

    .p-tabview-selected {
        background: #bc000e;

        a {
            color: #fff;
        }
    }
}

.p-button-icon-only {
    margin-right: 5px !important;
    border-radius: 5px !important;
}

.p-accordion-header-link {
    color: #000;
    border: 1px solid #c8c8c8;
}

.input-time {
    position: relative;
    overflow: hidden;

    .clock-img {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 0;
    }

    input.form-control::-webkit-calendar-picker-indicator {
        display: block;
        top: 0;
        left: 0;
        background: #0000;
        position: absolute;
        transform: scale(12);
        width: 100%;
        z-index: 1;
        cursor: pointer;
    }
}

.own-calendar {
    button.p-datepicker-trigger {
        position: absolute;
        right: 0px;
        background: #fff;
        color: black;
        border: 0;
        top: 3px;
    }

    .p-inputtext {
        @extend .form-control;
    }
}

.inspeccion-add,
.checklist-despacha-fieldset {
    .p-fieldset {
        border: 1px solid #c8c8c8;
        @extend .mb-3;

        .p-fieldset-legend {
            padding: 0.857rem 1rem;
            width: auto;
            margin-left: 15px;
        }
    }
}

.p-datepicker.p-datepicker-touch-ui {
    min-width: auto;
}

.p-datepicker {
    padding: 0;

    .p-datepicker-buttonbar {
        padding: 0;
    }

    .p-datepicker-calendar {
        margin: 0px;
    }

    .p-datepicker-header {
        border-bottom: 1px solid #e5e7eb;

        .p-datepicker-title {
            line-height: normal;
        }
    }
}

.image-group {
    border: 1px solid #9b9b9b;
    border-radius: 5px;
    margin-bottom: 15px;

    .title {
        margin: 0px;
        padding: 5px 10px;
    }

    .form-group {
        margin: 0px;
        border-top: 1px solid #efefef;
    }

    .custom-file-upload {
        padding: 10px 10px;
    }

    .file-info {
        padding: 10px 10px;
        background: lightgreen;
    }
}

.col-6 .image-group {
    .label-info {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 400px;
    }
}

.col-4 .image-group {
    .label-info {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 250px;
    }
}

.col-12 .image-group {
    .label-info {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 250px;
    }
}

.p-paginator-page {
    background-color: #bc000e;
}

@media (max-width: 767px) {
    .left-sidebar + .page-wrapper {
        > .page-content {
            padding: 30px 0;
        }
    }
    .p-datatable-tbody tr:not(.p-datatable-emptymessage) td:last-child {
        padding-left: 0;
        padding-right: 0;
        > div > button:last-child {
            margin: 0 !important;
        }
    }
    .main_table > div {
        margin: 0;
        padding: 0;
    }
}

@media (max-width: 450px) { 
    .p-paginator-bottom.p-paginator.p-component {
        > span.p-paginator-current {
            width: 100%
        }
    }
}
.action_col {
    padding-left: 0;
    padding-right: 0;
    button:last-child {
        margin-right: 0 !important;
    }
}
.p-paginator-pages {
    button.p-paginator-page {
        &:not(:last-child) {
            margin-right: 5px
        }
        margin: 2px 0;
    }
}